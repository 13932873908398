import {Link} from "koye";
import Logo from "../images/svg/logo.svg";
import PlayStoreWhite from "../images/play_store_white.png";
import {Image2} from "./image";
import React from "react";


const SecondaryHeader = () => (
    <div style={{flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <Image2 src={"error_main.png"} style={{display: 'flex', flex: 1}} className={"main-404"}/>
        <div style={{position: 'absolute', top: 0, width: '100%', display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{maxWidth: 960, width: '100%', display: 'flex', flex: 1, padding: `1.05rem 1.0875rem`}}>
                <div style={{width: '50%', display: 'flex', alignItems: 'center'}}>
                    <Link to="/" style={{textDecoration: `none`, flex: 1, flexDirection: 'row', display: 'flex', alignItems: 'center'}}>
                        <Logo style={{width: 40, height: 50, marginBottom: 0, marginRight: '0.725rem'}} className={"hide-logo"} />
                        <span className={"secondary-header-logo"} style={{color: '#fff', fontSize: 45, fontFamily: 'RooneySans, sans-serif'}}>Koyeto</span>
                    </Link>
                </div>
                <div style={{width: '50%', textAlign: 'center', display: 'flex', flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <a style={{
                            color: `white`,
                            textDecoration: `none`,
                        }}
                        href="https://play.google.com/store/apps/details?id=app.koyeto.android"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={PlayStoreWhite} alt="" style={{marginRight: '10px', marginBottom: 0}} className={"icon-size button-hover button-active"}/>
                    </a>
                </div>
            </div>
        </div>

    </div>
);

export default SecondaryHeader